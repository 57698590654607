import {createStore} from 'vuex'
import {message} from "@/store/message";
import {keyword} from "@/store/keyword";

// 创建一个新的 store 实例
export default createStore({
	state: {
		token: localStorage.getItem("accessToken"),
		nickName: localStorage.getItem("nickName"),
		phoneNumber: localStorage.getItem("phoneNumber"),
		avatar: localStorage.getItem("avatar"),
		type: localStorage.getItem("type"),
		companyIdList: localStorage.getItem("companyIdList"),
		personalizationSwitch: localStorage.getItem("personalizationSwitch")
	},
	getters: {
		getToken: state => state.token,
		getNickName: state => state.nickName == undefined ? "未登录" : state.nickName,
		logoutAble: state => state.token == undefined ? false : true,
		getPhoneNumber: state => state.phoneNumber == undefined ? "未登录" : state.phoneNumber,
		getAvatar: state => state.avatar == undefined ? null : state.avatar,
		getType: state => state.type == undefined ? null : state.type,
		getCompanyIdList: state => state.companyIdList == undefined ? null : state.companyIdList,
		personalizationSwitch: state => state.personalizationSwitch == undefined ? null : state.personalizationSwitch,
	},
	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
		},
		SET_NICKNAME: (state, nickName) => {
			state.nickName = nickName
		},
		SET_PHONE_NUMBER: (state, phoneNumber) => {
			state.phoneNumber = phoneNumber
		},
		SET_AVATAR: (state, avatar) => {
			state.avatar = avatar
		},
		SET_TYPE: (state, type) => {
			state.type = type
		},
		SET_COMPANY_ID_LIST: (state, companyIdList) => {
			state.companyIdList = companyIdList
		},
		SET_PERSONALIZATION_SWITCH: (state, personalizationSwitch) => {
			state.personalizationSwitch = personalizationSwitch
		},
	},
	actions: {},
	modules: {message,keyword}
})