import { createApp } from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import { Lazyload, Toast } from "vant";
import { VueMasonryPlugin } from "vue-masonry";
import "vant/lib/index.css";
import dict from "./components/Dict"; // 数据字典
import loadJs from "./loadJs";
import { htmlDecode, htmlPlain } from "./utils";

const app = createApp(App);

// 导入axios
import axios from "axios";
// 默认请求的基础url（如果axios请求的地址不带域名，自动添加baseURL）（默认请求域名，/当前域名）
// axios.defaults.baseURL = "/";
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;
// 设置post请求头的content-Type值

axios.defaults.headers.post["Content-Type"] =
	"application/x-www-form-urlencoded";
// 请求头设置租户id
// axios.defaults.headers.common["tenant_id"] = 118;
axios.defaults.headers.common["tenant_id"] = process.env.VUE_APP_TENANT_ID;
// 请求超时5000毫秒
axios.defaults.timeout = 30000;
axios.interceptors.request.use(
	async function (config) {
		config.headers["Authorization"] = "Bearer " + store.state.token;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
axios.interceptors.response.use(
	function (config) {
		if (config.data.code == 401) {
			loadJs.checkWXJs().then((a) => {
				localStorage.removeItem("accessToken");
				localStorage.removeItem("nickName");
				localStorage.removeItem("phoneNumber");
				localStorage.removeItem("type");
				localStorage.removeItem("companyIdList");
				localStorage.removeItem("avatar");
				store.commit("SET_NICKNAME",undefined)
				store.commit("SET_TOKEN",undefined)
				store.commit("SET_PHONE_NUMBER",undefined)
				store.commit("SET_TYPE",undefined)
				store.commit("SET_COMPANY_ID_LIST",undefined)
				store.commit("SET_AVATAR",undefined)
				const u = navigator.userAgent;
				const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
				//非小程序和ios跳转到登录页
				Toast.fail("登录已过期，请重新登陆");
				if (a == 1) {
					window.wx.miniProgram.navigateTo({
						url: "../login/login",
					});
				} else if (a == 0 && isiOS) {
					window.webkit.messageHandlers.jsGetNativeUserInfo.postMessage(null);
				} else {
					window.location.href = "/login";
				}
				return;
			});
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
//把axios挂载到vue上，并起一个叫$http的名字
app.config.globalProperties.$http = axios;
app.config.globalProperties.$htmlDecode = htmlDecode;
app.config.globalProperties.$htmlPlain = htmlPlain;
app.use(dict);
app.use(router);
app.use(store);
app.mount("#app");
app.use(Lazyload, {
	lazyComponent: true,
});
app.use(VueMasonryPlugin);
