import loadJs from "./loadJs";
import { tran } from "./utils";
import { ShareSheet } from "vant";
import "vant/es/toast/style";
import { mapActions, mapState } from "vuex";
import _ from "lodash";
export default {
  components: {
    [ShareSheet.name]: ShareSheet
  },
  computed: { ...mapState(["dataArray"]),

    dataArrayLength() {
      let dataArray = this.$store.getters.getDataArray;

      let number = _.sumBy(dataArray, item => parseInt(item.data.datas.status));

      if (number > 0) {
        if (number >= 100) {
          number = "99+";
        }

        return number;
      } else {
        return "0";
      }
    }

  },

  data() {
    return {
      isApple: false,
      isMachine: false,
      title: "首页",
      active: 0,
      baseHeight: 0,
      keyborad: false,
      setting: false,
      message: false,
      gList: [{
        id: "1",
        img: require("/src/assets/tab_home.svg"),
        active: require("/src/assets/tab_home_active.svg"),
        path: "/home",
        text: "首页"
      }, // {
      //   id: "2",
      //   img: require("/src/assets/tab_search.svg"),
      //   active: require("/src/assets/tab_search_active.svg"),
      //   path: "/search",
      //   text: "追溯",
      // },
      {
        id: "3",
        img: require("/src/assets/tab_product.svg"),
        active: require("/src/assets/tab_product_active.svg"),
        path: "/masterProduct",
        text: "产品展示"
      }, {
        id: "4",
        img: require("/src/assets/tab_master.svg"),
        active: require("/src/assets/tab_master_active.svg"),
        path: "/masterTab",
        text: "建盏名家"
      }, {
        id: "5",
        img: require("/src/assets/tab_knowledge.svg"),
        active: require("/src/assets/tab_knowledge_active.svg"),
        path: "/knowledge",
        text: "建盏知识"
      }, {
        id: "6",
        img: require("/src/assets/tab_me.svg"),
        active: require("/src/assets/tab_me_active.svg"),
        path: "/my",
        text: "我的"
      }],
      showShare: false,
      options: [{
        name: "微信好友",
        icon: "wechat"
      }, {
        name: "朋友圈",
        icon: "wechat-moments"
      }],
      isWechatWeb: false,
      //  messageSize消息数量
      messageSize: "0"
    };
  },

  created() {
    loadJs.loadJs(loadJs.wxjs).then(() => {
      // 加载成功，进行后续操作
      loadJs.checkWXJs().then(a => {
        const u = navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

        if (isiOS && a == 0) {
          this.isApple = true;
        } else {
          this.isApple = false;
        }

        if (a == 2) {
          this.isWechatWeb = true;
        }
      });
    });

    try {
      this.isMachine = window.jz.isPhoneController;
    } catch (error) {
      // 处理异常
      console.error('捕获异常：', error);
    }
  },

  mounted: function () {
    window.jsReciveUserInfo = this.jsReciveUserInfo;
    window.pageBack = this.pageBack;
    window.scanBase = this.scanBase;
    window.notifiyMessage = this.notifiyMessage;
    window.saveMessage = this.saveMessage;
    this.baseHeight = this.$refs.appbody.offsetHeight;
    window.addEventListener("resize", () => {
      //
      this.keyborad = !this.keyborad;
      this.$refs.appbody.style.height = this.baseHeight + "px";
    });
  },
  methods: { //异步，会持久化到localStorage中
    ...mapActions(["addAndPersist", "deleteAndPersist", "updateAndPersist"]),

    jsReciveUserInfo(userInfo) {
      localStorage.setItem("accessToken", userInfo.token);
      localStorage.setItem("nickName", userInfo.nickName);
      localStorage.setItem("phoneNumber", userInfo.phoneNumber);
      localStorage.setItem("type", userInfo.type);
      localStorage.setItem("companyIdList", userInfo.companyIdList);
      this.$store.commit("SET_TOKEN", userInfo.token);
      this.$store.commit("SET_NICKNAME", userInfo.nickName);
      this.$store.commit("SET_PHONE_NUMBER", userInfo.phoneNumber);
      this.$store.commit("SET_TYPE", userInfo.type);
      this.$store.commit("SET_COMPANY_ID_LIST", userInfo.companyIdList);
    },

    onSelect(option) {
      this.showShare = false;

      if (this.$route.name == "productDetail") {
        this.doShare(option.name, this.$refs.modalForm.cupInfo.cupName, this.$refs.modalForm.craftsman, this.$route.fullPath);
      }

      if (this.$route.name == "articleDetail") {
        this.doShare(option.name, this.$refs.modalForm.title, this.$refs.modalForm.author, this.$route.fullPath);
      }
    },

    doShare(name, title, content, url) {
      if (name == "微信好友") {
        window.jz.shareWechat(title, content, url);
      } else if (name == "朋友圈") {
        window.jz.sharePyq(title, content, url);
      }
    },

    scanBase(msg) {
      if (typeof msg == "string" && msg.length > 0) {
        if (msg.includes("-")) {
          msg = tran(msg);
          this.$router.push({
            name: "productDetail",
            params: {
              id: msg
            }
          });
        } else {
          // 跳转套装
          if (msg.includes("http")) {
            var index = msg.lastIndexOf('/');
            msg = msg.substring(index + 1);
          }

          this.$router.push({
            name: "suitDetail",
            params: {
              id: msg,
              toSuit: true
            }
          });
        }
      } else {
        console.log("msg: " + msg);
      }
    },

    //保存信息
    saveMessage(msg) {
      console.log("saveMessage", msg);
      var data = JSON.parse(msg);

      if (Array.isArray(data)) {
        data.forEach(item => {
          console.log("saveMessagedata:", item);
          console.log("保存成功");
          this.$store.dispatch("addAndPersist", item);
        });
      } else {
        console.log("saveMessagedata:", data); //保存消息

        console.log("保存成功");
        this.$store.dispatch("addAndPersist", data);
      }
    },

    //点击跳转
    notifiyMessage(msg) {
      console.log("notifiyMessage", msg);
      var data = JSON.parse(msg);

      if (data.path) {
        this.$router.push({
          name: data.path,
          params: data.datas
        });
      }
    },

    onChange(index) {
      this.title = this.gList[index].text;
    },

    //toscan
    toScan() {
      if (this.$route.meta.scan) {
        loadJs.checkWXJs().then(a => {
          if (a == 1) {
            window.wx.miniProgram.navigateTo({
              url: "../scan/scan?type=1&hastoken=" + this.$store.getters.logoutAble
            });
          } else if (a == 0) {
            window.jz.scan("scanBase");
          }
        });
      }
    },

    toExpand() {
      window.jz.connectMachine("11");
    },

    toShare() {
      if (this.$route.meta.share) {
        this.showShare = true;
      }
    },

    toMessage() {
      this.$router.push({
        name: "message"
      });
    },

    toPhoto() {
      let cupInfo = JSON.stringify(this.$refs.modalForm.cupInfo);
      this.$router.push({
        name: "cupSupplement",
        query: {
          cupInfo: cupInfo
        }
      });
    },

    toSetting() {
      this.$router.push({
        name: "setUp"
      });
    },

    onClickRight() {},

    onClickLeft() {
      if (window.history.length > 1) {
        this.$router.back();
      } else {
        if (this.$router.currentRoute.value.fullPath.startsWith("/product/detail") || this.$router.currentRoute.value.fullPath.endsWith("/searching/guide") || this.$router.currentRoute.value.fullPath.endsWith("/register") || this.$router.currentRoute.value.fullPath.endsWith("/forgot")) {
          if (this.$router.currentRoute.value.query.share != undefined) {
            this.$router.push("/");
          } else window.wx.miniProgram.navigateBack();
        } else this.$router.push("/");
      }
    },

    pageBack() {
      if (this.$router.currentRoute.value.path == "/home" || this.$router.currentRoute.value.path == "/masterTab" || this.$router.currentRoute.value.path == "/masterProduct" || this.$router.currentRoute.value.path == "/knowledge" || this.$router.currentRoute.value.path == "/my") {
        return true;
      }

      this.$router.back();
      return false;
    }

  }
};