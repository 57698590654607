// keyword.js

export const keyword = {
	state: {
		keywordArray: localStorage.getItem("keywordArray") != null ? JSON.parse(localStorage.getItem("keywordArray")) : []
	},
	mutations: {
		addKeyWord(state, data) {
			state.keywordArray.unshift(data);
		},
		deleteKeyWord(state, index) {
			state.keywordArray.splice(index, 1);
		},
		updateKeyWord(state, {index, newData}) {
			state.keywordArray[index] = newData;
		},
		clearKeyWords(state) {
			state.keywordArray = [];
		},
		//删除指定长度
		deleteKeyWords(state, index) {
			state.keywordArray.splice(index);
		},
	},
	actions: {
		addKeyWordActions({commit, state}, data) {
			commit("addKeyWord", data);
			localStorage.setItem("keywordArray", JSON.stringify(state.keywordArray));
		},
		deleteKeyWordActions({commit, state}, index) {
			commit("deleteKeyWord", index);
			localStorage.setItem("keywordArray", JSON.stringify(state.keywordArray));
		},
		updateKeyWordActions({commit, state}, {index, newData}) {
			commit("keywordArray", {index, newData});
			localStorage.setItem("keywordArray", JSON.stringify(state.keywordArray));
		},
		clearKeyWordsActions({commit, state}) {
			commit("clearKeyWords");
			localStorage.removeItem("keywordArray");
		},
		deleteKeyWordsActions({commit, state},index) {
			commit("deleteKeyWords",index);
			localStorage.setItem("keywordArray", JSON.stringify(state.keywordArray));
		}
	},
	getters: {
		getKeyWordArray: state => {
			return state.keywordArray;
		}
	}
}
