import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createSlots as _createSlots, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-18cfb230"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "iosAdapter",
  ref: "appbody"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");

  const _component_van_tag = _resolveComponent("van-tag");

  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  const _component_router_view = _resolveComponent("router-view");

  const _component_van_tabbar_item = _resolveComponent("van-tabbar-item");

  const _component_van_tabbar = _resolveComponent("van-tabbar");

  const _component_van_share_sheet = _resolveComponent("van-share-sheet");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$route.meta.showTab && !$data.isApple && _ctx.$route.meta.title !== '建盏名家' ? (_openBlock(), _createBlock(_component_van_nav_bar, {
    key: 0,
    fixed: "",
    title: _ctx.$route.meta.title,
    "left-arrow": _ctx.$route.meta.arrow && !$data.isWechatWeb,
    onClickLeft: $options.onClickLeft,
    onClickRight: $options.onClickRight,
    placeholder: ""
  }, _createSlots({
    _: 2
  }, [_ctx.$route.meta.right && !$data.isWechatWeb ? {
    name: "right",
    fn: _withCtx(() => [_ctx.$route.meta.scan && !$data.isApple ? (_openBlock(), _createBlock(_component_van_icon, {
      key: 0,
      name: "scan",
      size: "18",
      onClick: $options.toScan
    }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.$route.meta.photo && !$data.isApple ? (_openBlock(), _createBlock(_component_van_icon, {
      key: 1,
      name: "photo-o",
      size: "18",
      style: {
        "margin": "1rem"
      },
      onClick: $options.toPhoto
    }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.$route.meta.expand && $data.isMachine ? (_openBlock(), _createBlock(_component_van_icon, {
      key: 2,
      name: "expand-o",
      size: "18",
      onClick: $options.toExpand
    }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.$route.meta.share ? (_openBlock(), _createBlock(_component_van_icon, {
      key: 3,
      name: "share-o",
      size: "18",
      onClick: $options.toShare
    }, null, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.$route.meta.message ? (_openBlock(), _createBlock(_component_van_icon, {
      key: 4,
      name: "bell",
      size: "18",
      style: {
        "margin": "1rem"
      },
      onClick: $options.toMessage
    }, {
      default: _withCtx(() => [$options.dataArrayLength !== '0' ? (_openBlock(), _createBlock(_component_van_tag, {
        key: 0,
        round: "",
        type: "danger",
        class: "tag-number2"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($options.dataArrayLength), 1)]),
        _: 1
      })) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.$route.meta.setting ? (_openBlock(), _createBlock(_component_van_icon, {
      key: 5,
      name: "setting-o",
      size: "18",
      onClick: $options.toSetting
    }, null, 8, ["onClick"])) : _createCommentVNode("", true)])
  } : undefined]), 1032, ["title", "left-arrow", "onClickLeft", "onClickRight"])) : _createCommentVNode("", true), _createVNode(_component_router_view, null, {
    default: _withCtx(({
      Component
    }) => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive != null && _ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      ref: "modalForm",
      key: _ctx.$route.name
    })) : _createCommentVNode("", true)], 1024)), _ctx.$route.meta.keepAlive == null || !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
      ref: "modalForm",
      key: _ctx.$route.name
    })) : _createCommentVNode("", true)]),
    _: 1
  }), _ctx.$route.meta.showNav && !$data.isApple ? (_openBlock(), _createBlock(_component_van_tabbar, {
    key: 1,
    route: "",
    fixed: !$data.keyborad,
    modelValue: $data.active,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.active = $event),
    "inactive-color": "#B39681",
    "active-color": "#eedcdc",
    onChange: $options.onChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.gList, (item, index) => {
      return _openBlock(), _createBlock(_component_van_tabbar_item, {
        key: item.id,
        icon: $data.active == index ? item.active : item.img,
        to: item.path
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.text), 1)]),
        _: 2
      }, 1032, ["icon", "to"]);
    }), 128))]),
    _: 1
  }, 8, ["fixed", "modelValue", "onChange"])) : _createCommentVNode("", true), _createVNode(_component_van_share_sheet, {
    show: $data.showShare,
    "onUpdate:show": _cache[1] || (_cache[1] = $event => $data.showShare = $event),
    title: "立即分享给好友",
    options: $data.options,
    onSelect: $options.onSelect
  }, null, 8, ["show", "options", "onSelect"])], 512);
}