// message.js


export const message = {
	state: {
		dataArray: localStorage.getItem("dataArray") != null ? JSON.parse(localStorage.getItem("dataArray")) : []
	},
	mutations: {
		addData(state, data) {
			//新增数据放在前面
			state.dataArray.unshift(data);
		},
		deleteData(state, index) {
			state.dataArray.splice(index, 1);
			console.log(state.dataArray)
		},
		updateData(state, {index, newData}) {
			state.dataArray[index] = newData;
		}
	},
	actions: {
		addAndPersist({commit, state}, data) {
			commit("addData", data);
			localStorage.setItem("dataArray", JSON.stringify(state.dataArray));
		},
		deleteAndPersist({commit, state}, index) {
			commit("deleteData", index);
			localStorage.setItem("dataArray", JSON.stringify(state.dataArray));
		},
		updateAndPersist({commit, state}, {index, newData}) {
			commit("updateData", {index, newData});
			localStorage.setItem("dataArray", JSON.stringify(state.dataArray));
		}
	},
	getters: {
		getDataArray: state => {
			return state.dataArray;
		}

	}
}
