
export function tran(text) {
  if (text.includes("http")) {
    var index = text.lastIndexOf('/')
    text = text.substring(index + 1)
    console.log(text)
  }
  if (!text.includes("-")) {
    text =
      text.slice(0, 3) +
      "-" +
      text.slice(3, 4) +
      "-" +
      text.slice(4, 5) +
      "-" +
      text.slice(5, 6) +
      "-" +
      text.slice(6, 7) +
      "-" +
      text.slice(7, 9) +
      "-" +
      text.slice(9, 11) +
      "-" +
      text.slice(11, 19) +
      "-" +
      text.slice(19, 25);
  }
  return text;
}


export function htmlDecode(text) {
  if (undefined == text || null == text) {
    return "";
  }
  if (text.indexOf("&lt;") >= 0
    && text.indexOf("&gt;") >= 0
    && (
      text.indexOf("&lt;div") >= 0
      || text.indexOf("&lt;p") >= 0
      || text.indexOf("&lt;h") >= 0
      || text.indexOf("&lt;span") >= 0
      || text.indexOf("&lt;a") >= 0
    )) {
    var temp = document.createElement("div");
    temp.innerHTML = text;
    var output = temp.innerText || temp.textContent;
    temp = null;
    return output;
  } else {
    return text;
  }
}

export function htmlPlain(text) {
  if (undefined == text || null == text) {
    return "";
  }
  var temp;
  if (text.indexOf("&lt;") >= 0
    && text.indexOf("&gt;") >= 0
    && (
      text.indexOf("&lt;div") >= 0
      || text.indexOf("&lt;p") >= 0
      || text.indexOf("&lt;h") >= 0
      || text.indexOf("&lt;span") >= 0
      || text.indexOf("&lt;a") >= 0
    )) {
    temp = document.createElement("div");
    temp.innerHTML = text;
    text = temp.innerText || temp.textContent;
    temp = null;
  }
  if (text.indexOf("<") >= 0
    && text.indexOf(">") >= 0
    && (
      text.indexOf("<div") >= 0
      || text.indexOf("<p") >= 0
      || text.indexOf("<h") >= 0
      || text.indexOf("<span") >= 0
      || text.indexOf("<a") >= 0
    )) {
    temp = document.createElement("div");
    temp.innerHTML = text;
    text = temp.innerText || temp.textContent;
    temp = null;
  }
  return text;
}

export function checkIos() {
  const u = navigator.userAgent;
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  return isiOS;
}

export function goLogin(type, router) {
  if (type == 1) {
    window.wx.miniProgram.navigateTo({
      url: "../login/login",
    });
  } else if (type == 0) {
    const u = navigator.userAgent;
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isiOS) {
      window.webkit.messageHandlers.jsGetNativeUserInfo.postMessage(null);
    } else {
      router.push({ name: "login" });
    }
  } else {
    router.push({ name: "login" });
  }
}