const wxjs = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js"

var loadJs = function (src) {
  return new Promise((resolve, reject) => {
    let script = document.createElement('script');
    script.type = "text/javascript";
    script.src = src;
    document.body.appendChild(script);

    script.onload = () => {
      resolve();
    }
    script.onerror = () => {
      reject();
    }
  }).then(function () {
  }).catch(function () {
  })
}

var checkWXJs = function () {
  return new Promise((resolve) => {
    // 加载成功，进行后续操作
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      //微信环境下
      window.wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          // 小程序环境下逻辑
          resolve(1)
        } else {
          //非小程序环境下逻辑
          resolve(2)
        }
      });
    } else{
      resolve(0)
    }
  })
}

export default { loadJs, checkWXJs, wxjs }