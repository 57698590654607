import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		redirect: "/home",
	},
	{
		name: "knowledge",
		path: "/knowledge",
		component: () => import("./components/knowledgeTab"),
		meta: {
			showTab: true,
			showNav: true,
			title: "建盏知识",
            keepAlive: true,
		},
	},
	{
		name: "me",
		path: "/my",
		component: () => import("./components/myTab"),
		meta: {
			showTab: true,
			showNav: true,
			title: "我的",
			arrow: false,
			right: true,
			setting: true,
			message: true,
            keepAlive: true,
		},
	},
	{
		name: "home",
		path: "/home",
		component: () => import("./components/homeTab"),
		meta: {
			showTab: true,
			showNav: true,
			title: "首页",
			arrow: false,
			right: true,
			scan: true,
            keepAlive: true,
		},
	},
	{
		name: "error",
		path: "/error",
		component: () => import("./components/errorTab"),
		meta: {
			showTab: false,
			showNav: false,
			title: "错误",
			arrow: true,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "product",
		path: "/product",
		component: () => import("./components/productTab"),
		meta: {
			showTab: true,
			showNav: false,
			title: "产品展示",
			arrow: true,
			right: false,
			isBack: true,
            keepAlive: true,
		},
	},
	{
		name: "masterProduct",
		path: "/masterProduct",
		component: () => import("./components/masterProductTab"),
		meta: {
			showTab: true,
			showNav: true,
			title: "产品展示",
			arrow: false,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "productFamous",
		path: "/product/famous",
		component: () => import("./components/productList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "名作鉴赏",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "productSearch",
		path: "/product/search",
		component: () => import("./components/productList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "产品搜索",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "homeSearch",
		path: "/home/search",
		component: () => import("./components/allSearchList"),
		meta: {
			showTab: true,
			showNav: true,
			title: "搜索",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "productHot",
		path: "/product/hot",
		component: () => import("./components/productList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "热门建盏",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "about",
		path: "/about",
		component: () => import("./components/aboutTab"),
		meta: {
			showTab: true,
			showNav: false,
			title: "关于",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "report",
		path: "/report",
		component: () => import("./components/report"),
		meta: {
			showTab: true,
			showNav: false,
			title: "举报",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "forum",
		path: "/forum",
		component: () => import("./components/forum"),
		meta: {
			showTab: true,
			showNav: false,
			title: "论坛",
			right: false,
			arrow: true,
            keepAlive: true,
		},
	},
	{
		name: "myComment",
		path: "/myComment",
		component: () => import("./components/myComment"),
		meta: {
			showTab: true,
			showNav: false,
			title: "我的评论",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "message",
		path: "/message",
		component: () => import("./components/message"),
		meta: {
			showTab: true,
			showNav: false,
			title: "消息中心",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "setUp",
		path: "/setUp",
		component: () => import("./components/setUp"),
		meta: {
			showTab: true,
			showNav: false,
			title: "设置",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "personalInformation",
		path: "/personalInformation",
		component: () => import("./components/personal/personalInformation.vue"),
		meta: {
			showTab: true,
			showNav: false,
			title: "个人信息",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},

	{
		name: "nickname",
		path: "/nickname",
		component: () => import("./components/personal/nickname.vue"),
		meta: {
			showTab: true,
			showNav: false,
			title: "昵称",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "suggest",
		path: "/suggest",
		component: () => import("./components/suggest"),
		meta: {
			showTab: true,
			showNav: false,
			title: "建议",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "changePassword",
		path: "/changePassword",
		component: () => import("./components/changePassword"),
		meta: {
			showTab: true,
			showNav: false,
			title: "",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "collection",
		path: "/collection",
		component: () => import("./components/collection"),
		meta: {
			showTab: true,
			showNav: false,
			title: "我的收藏",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "follow",
		path: "/follow",
		component: () => import("./components/follow"),
		meta: {
			showTab: true,
			showNav: false,
			title: "我的关注",
			right: false,
			arrow: true,
            keepAlive: false,
		},
	},
	{
		name: "login",
		path: "/login",
		component: () => import("./components/userLogin"),
		meta: {
			showTab: true,
			showNav: false,
			title: "",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "register",
		path: "/register",
		component: () => import("./components/userRegister"),
		meta: {
			showTab: true,
			showNav: false,
			title: "",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "forgot",
		path: "/forgot",
		component: () => import("./components/forgotPassword"),
		meta: {
			showTab: true,
			showNav: false,
			title: "",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "bind",
		path: "/bind",
		component: () => import("./components/bindPhone"),
		meta: {
			showTab: true,
			showNav: false,
			title: "",
			arrow: true,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "tranLoading",
		path: "/loadding",
		component: () => import("./components/transitionLoading"),
		meta: {
			showTab: true,
			showNav: false,
			title: "",
			arrow: false,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "productNew",
		path: "/product/new",
		component: () => import("./components/productList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "新品推荐",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},

	{
		name: "productSuit",
		path: "/product/suit",
		component: () => import("./components/productList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "套装建盏",
			arrow: true,
			right: false,
			isBack: false,
			keepAlive: true,
		},
	},
	{
		name: "productDetail",
		path: "/product/detail/:id",
		component: () => import("./components/traceResultTab"),
		meta: {
			showTab: true,
			showNav: false,
			title: "产品详情",
			arrow: true,
			right: true,
			share: true,
			photo: false,
            keepAlive: false,
		},
	},

	{
		name: "suitDetail",
		path: "/suit/detail/:id",
		component: () => import("./components/suitResultTab"),
		meta: {
			showTab: true,
			showNav: false,
			title: "套装详情",
			arrow: true,
			right: true,
			share: false,
			photo: false,
			keepAlive: false,
		},
	},
	{
		name: "master",
		path: "/master",
		component: () => import("./components/masterList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "建盏名家",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "masterTab",
		path: "/masterTab",
		component: () => import("./components/masterList"),
		meta: {
			showTab: true,
			showNav: true,
			title: "建盏名家",
			arrow: false,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "masterDetail",
		path: "/master/detail/:id",
		component: () => import("./components/masterDetail"),
		meta: {
			showTab: false,
			showNav: false,
			title: "",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "reportDetail",
		path: "/reportDetail/detail/:id",
		component: () => import("./components/reportDetail"),
		meta: {
			showTab: true,
			showNav: true,
			title: "质检报告",
			arrow: true,
			right: true,
            keepAlive: true,
		},
	},
	{
		name: "cupSupplement",
		path: "/cupSupplement",
		component: () => import("./components/cupSupplement"),
		meta: {
			showTab: true,
			showNav: false,
			title: "补录照片",
			arrow: true,
			right: true,
            keepAlive: true,
		},
	},
	{
		name: "search",
		path: "/search",
		component: () => import("./components/searchTab"),
		meta: {
			showTab: true,
			showNav: true,
			title: "追溯",
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "searching",
		path: "/searching",
		component: () => import("./components/cupSearch"),
		meta: {
			showTab: true,
			showNav: false,
			title: "AI鉴盏",
			arrow: true,
			right: true,
			expand: true,
            keepAlive: false,
		},
	},
	{
		name: "collecting",
		path: "/collecting",
		component: () => import("./components/cupCollect"),
		meta: {
			showTab: true,
			showNav: false,
			title: "AI鉴盏",
			arrow: true,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "traceResult",
		path: "/traceResult/:id",
		component: () => import("./components/traceResultTab"),
		meta: {
			showTab: true,
			showNav: false,
			title: "追溯详情",
			arrow: true,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "news",
		path: "/article/news",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "媒体资讯",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "skill",
		path: "/article/skill",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "鉴赏宝典",
			arrow: true,
			isBack: false,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "technology",
		path: "/article/technology",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "工艺鉴赏",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "culture",
		path: "/article/culture",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "文化历史",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "other",
		path: "/article/other",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "其他",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "element",
		path: "/article/element",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "入门知识",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "activity",
		path: "/article/activity",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "活动咨讯",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "industryInformation",
		path: "/article/industryInformation",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "产业信息",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "governmentInformation",
		path: "/article/governmentInformation",
		component: () => import("./components/articleList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "官方公告",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "concernInfo",
		path: "/concernInfo",
		component: () => import("./components/concernInfoList"),
		meta: {
			showTab: true,
			showNav: false,
			title: "关注",
			arrow: true,
			right: false,
			isBack: false,
            keepAlive: true,
		},
	},
	{
		name: "complain",
		path: "/user/complain",
		component: () => import("./components/userComplain"),
		meta: {
			showTab: true,
			showNav: false,
			title: "投诉建议",
			arrow: true,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "agreementUser",
		path: "/about/agreementUser",
		component: () => import("./components/agreementUser"),
		meta: {
			showTab: true,
			showNav: false,
			title: "用户协议",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "agreementPrivacy",
		path: "/about/agreementPrivacy",
		component: () => import("./components/agreementPrivacy"),
		meta: {
			showTab: true,
			showNav: false,
			title: "隐私协议",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "guideSearch",
		path: "/searching/guide",
		component: () => import("./components/guideSearch"),
		meta: {
			showTab: true,
			showNav: false,
			title: "Ai鉴盏引导",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "articleDetail",
		path: "/article/detail/:type/:id",
		component: () => import("./components/articleDetail"),
		meta: {
			showTab: true,
			showNav: false,
			title: "",
			arrow: true,
			right: true,
			share: true,
            keepAlive: false,
		},
	},
	{
		name: "jump",
		path: "/jump",
		component: () => import("./components/jumpTab"),
		meta: {
			showTab: true,
			showNav: false,
			title: "",
			arrow: true,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "postDetail",
		path: "/postDetail",
		component: () => import("./components/postDetail"),
		meta: {
			showTab: true,
			showNav: false,
			title: "帖子",
			arrow: true,
			right: true,
            keepAlive: false,
		},
	},
	{
		name: "searchPage",
		path: "/searchPage",
		component: () => import("./components/searchPage"),
		meta: {
			showTab: true,
			showNav: true,
			title: "搜索",
			arrow: true,
			right: false,
            keepAlive: true,
		},
	},
	{
		name: "vote01",
		path: "/vote01",
		component: () => import("./components/activityTemplates/vote01"),
		meta: {
			showTab: false,
			showNav: false,
			title: "投票",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "vote02",
		path: "/vote02",
		component: () => import("./components/activityTemplates/vote02"),
		meta: {
			showTab: false,
			showNav: false,
			title: "投票",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "vote03",
		path: "/vote03",
		component: () => import("./components/activityTemplates/vote03"),
		meta: {
			showTab: false,
			showNav: false,
			title: "投票",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "vote04",
		path: "/vote04",
		component: () => import("./components/activityTemplates/vote04"),
		meta: {
			showTab: false,
			showNav: false,
			title: "投票",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "signUp01",
		path: "/signUp01",
		component: () => import("./components/activityTemplates/signUp01"),
		meta: {
			showTab: true,
			showNav: false,
			title: "报名参加",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "signUp02",
		path: "/signUp02",
		component: () => import("./components/activityTemplates/signUp02"),
		meta: {
			showTab: false,
			showNav: false,
			title: "报名参加",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "signUp03",
		path: "/signUp03",
		component: () => import("./components/activityTemplates/signUp03"),
		meta: {
			showTab: false,
			showNav: false,
			title: "报名参加",
			arrow: true,
			right: false,
            keepAlive: false,
		},
	},
	{
		name: "registerCompany",
		path: "/registerCompany",
		component: () => import("./components/registerCompany"),
		meta: {
			showTab: true,
			showNav: false,
			title: "注册企业",
			arrow: true,
			right: false,
			keepAlive: false,
		},
	},
	// 未找到延迟加载pdfh5的方法，写多个页面
	{
		name: "pdfPageRawOre",
		path: "/pdfPageRawOre",
		component: () => import("./components/pdfPageRawOre"),
		meta: {
			showTab: true,
			showNav: false,
			title: "原矿原釉",
			arrow: true,
			right: false,
			keepAlive: false,
		},
	},
	{
		name: "pdfPageGroup",
		path: "/pdfPageGroup",
		component: () => import("./components/pdfPageGroup"),
		meta: {
			showTab: true,
			showNav: false,
			title: "T/JYJZXH 1-2021 标准",
			arrow: true,
			right: false,
			keepAlive: false,
		},
	},
	{
		name: "pdfPageLocal",
		path: "/pdfPageLocal",
		component: () => import("./components/pdfPageLocal"),
		meta: {
			showTab: true,
			showNav: false,
			title: "DB35/T 1739-2018 标准",
			arrow: true,
			right: false,
			keepAlive: false,
		},
	},
];

const router = createRouter({
	routes,
	history: createWebHistory(),
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		if (to.name == "home" && sessionStorage.getItem("home")) {
			return { left: 0, top: sessionStorage.getItem("home") };
		}
		if (to.name == "product" && sessionStorage.getItem("product")) {
			return { left: 0, top: sessionStorage.getItem("product") };
		}
		if (to.name == "masterTab" && sessionStorage.getItem("masterTab")) {
			return { left: 0, top: sessionStorage.getItem("masterTab") };
		}
		return { left: 0, top: 0 };
	},
});

export { router };
